import React from "react"
import "../styles/Footer.css"

const Footer = props => (
    <div className= "footer-body">
        <p>
            © Joshua Wuebbolt
        </p>
    </div>
)
export default Footer;