import React from "react";
import "../styles/FunFacts.css"

const FunFacts = props => (
    <div className="FunFacts-section" id="FunFacts-section">
        <h1>FunFacts</h1>
        <span>Fun facts about me!</span>

        <div id="scroll-container">
            <div id="scroll-text">
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
                I held a world record for 2 weeks<br></br><br></br>
                I can make an Ethernet cable from scratch, the longest one I made was 60 metres (about 200ft)<br></br><br></br>
                I've compleated The Legend of Zelda: Ocarina of Time<br></br><br></br>
                I absolutely hate kinddom hearts<br></br><br></br>
                I can hold my breath for 2 minuets 46 seconds<br></br><br></br>
                I have never flown on a plane<br></br><br></br>
            </div>
            
        </div>
    </div>
);

export default FunFacts;